<template>
  <div class="anicom-report-table">
    <div class="table-header">
      <div class="cell select" v-if="tab === 'unsent'">
        <base-check-box
          class="check-box"
          :isChecked="allCheckFlg"
          @input="value => $emit('toggle-all-check-flg', value)"
        />
      </div>
      <div class="cell">承認番号発行日</div>
      <div class="cell">証券番号</div>
      <div class="cell">飼主名</div>
      <div class="cell">患者名</div>
      <div class="cell">診療費</div>
      <div class="cell">会計状態</div>
      <div class="cell is-send">送信</div>
    </div>
    <recycle-scroller
      v-if="data.length > 0"
      class="scroller"
      :items="data"
      :item-size="60"
      key-field="id"
      v-slot="{ item, index }"
    >
      <anicom-report-table-row
        class="row"
        v-bind="item"
        :index="index"
        :lastIndex="data.length - 1"
        :tab="tab"
        @input="obj => $emit('input', obj)"
        @scroll-bottom="$emit('scroll-bottom')"
        @clear="id => $emit('clear', id)"
      />
    </recycle-scroller>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import AnicomReportTableRow from '@/components/parts/organisms/AnicomReportTableRow'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  name: 'AnicomReportTable',

  components: { BaseCheckBox, AnicomReportTableRow, RecycleScroller },

  props: {
    data: { type: Array },
    allCheckFlg: { type: Boolean, default: false },
    tab: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
$box-border-color: #{$light-grey};
.anicom-report-table {
  > .table-header {
    font-size: 15px;
    box-sizing: border-box;
    display: flex;
    height: 42px;
    background-color: #{$pale};
    > .cell {
      border: 1px solid #{$light-grey};
      border-right: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      &.select {
        max-width: 50px;
      }
      &.is-send {
        border-right: 1px solid #{$light-grey};
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .scroller {
    max-height: 670px;
    border-bottom: 1px solid #{$light-grey};
  }
}
</style>
